import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Polis Conversation Data`}</h1>
    <ul>
      <li parentName="ul">{`A `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` `}<strong parentName="li">{`produces`}</strong>{` the following data:`}
        <ul parentName="li">
          <li parentName="ul">{`a collection of `}<a parentName="li" {...{
              "href": "/comments",
              "title": "comments"
            }}>{`comments`}</a>{``}</li>
          <li parentName="ul">{`a collection of `}<a parentName="li" {...{
              "href": "/votes",
              "title": "votes"
            }}>{`votes`}</a>{``}</li>
          <li parentName="ul">{`for each `}<a parentName="li" {...{
              "href": "/participant",
              "title": "participant"
            }}>{`participant`}</a>{`, the `}<a parentName="li" {...{
              "href": "/opinion-group",
              "title": "opinion group"
            }}>{`opinion group`}</a>{` they belong to as an integer`}</li>
          <li parentName="ul">{`for each `}<a parentName="li" {...{
              "href": "/opinion-group",
              "title": "opinion group"
            }}>{`opinion group`}</a>{`, the `}<a parentName="li" {...{
              "href": "/comments",
              "title": "comments"
            }}>{`comments`}</a>{` standing out as `}<a parentName="li" {...{
              "href": "/Representative-Comments",
              "title": "Representative Comments"
            }}>{`Representative Comments`}</a>{` for that `}<a parentName="li" {...{
              "href": "/group",
              "title": "group"
            }}>{`group`}</a>{``}</li>
          <li parentName="ul">{`the opinions most widely shared across `}<strong parentName="li">{`all`}</strong>{` groups`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/admin-interface",
          "title": "admin interface"
        }}>{`admin interface`}</a>{` displays `}<a parentName="li" {...{
          "href": "/Summary-Statistics",
          "title": "Summary Statistics"
        }}>{`Summary Statistics`}</a>{``}</li>
      <li parentName="ul">{`A data `}<a parentName="li" {...{
          "href": "/export",
          "title": "export"
        }}>{`export`}</a>{` is available at any time during the conversation`}</li>
    </ul>
    <p>{`See: `}<a parentName="p" {...{
        "href": "/export",
        "title": "export"
      }}>{`export`}</a>{``}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      